
import { mapGetters } from 'vuex'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDETag from '@/components/pde/PDETag.vue'
import { formatDateToMMMDDYYYY } from '@/utils/assignments'

export default {
  name: 'TimeEntryItem',

  components: {
    PDEButton,
    PDEHeading,
    PDEIcon,
    PDETag,
  },

  props: {
    assignment: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['enter-time-click'],

  computed: {
    ...mapGetters('identity', ['loggedInAsRep']),

    dateRange() {
      return `${formatDateToMMMDDYYYY(this.assignment.startDate)} - ${formatDateToMMMDDYYYY(this.assignment.endDate)}`
    },
  },
}
